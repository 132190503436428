
import React, { useEffect, useState } from 'react';
import Tekst from './deliverables/tekst.deliverable'
import Signature from './deliverables/signature.deliverable';









export default function Deliverable(props) {
   function Action() {
       return null
   }

 

    const componenten = {
tekst: Tekst,
action: Action,
signature: Signature
    }






const ReturnComponent = componenten[props.veld]||Action ;

  
    return (

<ReturnComponent  value={props.value} onAdd={props.onAdd} responsible={props.responsible} disabled={props.disabled}></ReturnComponent>

    )


}




