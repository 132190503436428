import React, { Component } from 'react';

import axios from 'axios';
import moment from 'moment';
import Button from 'react-bootstrap/Button'
import authService from '../services/auth.service';
import Navbar from './Navbar';
import Menu from "../components/menu.component";


export default class ProjectView extends Component {


    constructor(props) {
        super(props);
        this.handleTaskCheckbox = this.handleTaskCheckbox.bind(this);
        this.onComplete = this.onComplete.bind(this);


        this.state = {
            menu: { menutaken: [], menuprojecten: [] },
            checkedtasks: [],
            checklist: [],
            username: "Login",
            project: {
                project_name: '',
                project_start: "",
                project_end: "",
                project_team: [],
                project_taken: [],

            },
            projectdagen: 0
        };

    }


    componentDidMount() {


        this.projectID = this.props.match.params.id;
        const user = authService.getCurrentUser();
        if (user === 1) { return this.reDirect() }
        var userObject = { user: user }

        axios.post(authService.apiService() + this.projectID + '/project', userObject)
            .then(response => {
                var responseProject = response.data.project;

                responseProject.project_taken = response.data.taken;
                responseProject.project_taken.forEach(element => {
                    this.state.checklist.push({ taak: element, checked: false })

                });
                var menutaken = response.data.menutaken;

                let eind = new Date(responseProject.project_end);
                let start = new Date(responseProject.project_start);

                let dagen = (eind / (24 * 60 * 60 * 1000)) - (start / (24 * 60 * 60 * 1000));


                this.setState({ username: response.data.username, menu: menutaken, project: responseProject, projectdagen: Math.ceil(dagen) + 1 });


            })
            .catch(function (error) {
                console.log(error);
            })

    }
    reDirect() {
        this.props.history.push('/login');
    }
    handleTaskCheckbox(event) {
        const stateLijst = this.state.project;

        var index = event.target.id;

        const checked = stateLijst.project_taken[index].checked;
        if (checked) { stateLijst.project_taken[index].checked = false } else { stateLijst.project_taken[index].checked = true };
        const verzendLijst = [];

        stateLijst.project_taken.map((element) => {
            if (element.checked) { verzendLijst.push(element._id) }

        });


        this.setState({ checkedtasks: verzendLijst });

    }

    getDagBreedte() {
        return 662 + (10 * this.state.projectdagen) + "px";
    }

    makeProjectDagen() {
        var projectDagen = [];

        let startDatum = new Date(this.state.project.project_start);

        for (var i = 0; i < this.state.projectdagen; i++) {



            let taakDatum = moment(startDatum).add(i, 'days')
            var weekendDag = taakDatum.isoWeekday() === 6 || taakDatum.isoWeekday() === 7;
            var vandaag = taakDatum.isSame(new Date(), "day")



            let taakDag = { maand: taakDatum.format('MMMM yyyy'), weekend: weekendDag, datum: taakDatum, dag: taakDatum.format('dddd'), vandaag: vandaag };
            projectDagen.push(taakDag);
        }


        return projectDagen;



    }

    onComplete(e) {

        if (e.target.id === "get") { window.location.replace(e.target.value); }

        if (e.target.id === "post") {
            axios.post(e.target.value, this.state.checkedtasks)
                .then();
            window.location.replace('/project/' + this.projectID);

        }

    }
    getprojectMonths() {

        var maandlijst = this.makeProjectDagen();
        var terugLijst = [];

        var datumMap = new Map();

        maandlijst.forEach((maand) => {
            datumMap.set(maand.maand, datumMap.get(maand.maand) + 1 || 1)
        })


        terugLijst = Array.from(datumMap);







        return terugLijst;


    }


    render() {


        let ganttChart = {
            display: 'grid',
            width: this.getDagBreedte(),

            position: 'relative',

        }

        let taak = 0;
        var projectID = this.props.match.params.id;

        let linkLijst = [{
            linkname: "Open task", link: '/updatetask/' + projectID + "/task/" + this.state.checkedtasks[0], min: 1, max: 1, taak: "get"
        }, { linkname: "Delete task", link: "/projects/" + projectID + "/deletetasks/", min: 1, max: 500, taak: "post" }, { linkname: "New task", link: '/updatetask/' + projectID + "/task/" + taak, min: 0, max: 0, taak: "get" }];
        let memberLijst = [{ linkname: "Delete task", link: "/", klasse: 1, taak: "post" }, { linkname: "New member", link: '/addmember/' + projectID, klasse: 0, taak: "get" }];
        return (

<div className="indexcontainer">
            <div><Menu user={this.state.username} /></div>
             
            
                <div  style={{ marginTop: 10 }}>

                    




                    <div style={{ margin: '1em' }}>
                        <div class="row " >
                            <div class="col m-1 ">
                                <h3>Project info</h3>
                                <table className="table" style={{ marginTop: 20 }} >

                                    <tbody>




                                        <tr><td>
                                            <input type="checkbox" checked={false} id={1} name={null} ></input>
                                        </td>
                                            <th>Project name</th>
                                            <td >
                                                {this.state.project.project_name}

                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <input type="checkbox" checked={false} id={1} name={null} ></input>
                                            </td>
                                            <th>Project start</th>
                                            <td> {moment(this.state.project.project_start).format("DD/MM/YYYY")}</td>
                                        </tr>

                                        <tr>
                                            <td>
                                                <input type="checkbox" checked={false} id={1} name={null} ></input>
                                            </td>
                                            <th>Project end</th>
                                            <td> {moment(this.state.project.project_end).format("DD/MM/YYYY")}</td>
                                        </tr>






                                    </tbody>

                                </table>

                            </div>

                            <div class="col m-1">
                                <h3>Project team</h3>
                                <div class="d-grid gap-2 d-md-flex justify-content-md-start">



                                    {


                                        memberLijst.map((link) => {
                                            const aantalSelected = this.state.checkedtasks.length;
                                            if (link.klasse >= aantalSelected && link.klasse <= aantalSelected) {
                                                return (
                                                    <a class="btn btn-outline-primary" href={link.link} role="button">
                                                        {link.linkname}
                                                    </a>

                                                )
                                            }
                                            return (<div></div>)
                                        })}
                                </div>

                                <table className="table" style={{ marginTop: 20 }} >
                                    <thead>
                                        <tr> <th> <input type="checkbox" checked={false}></input></th>
                                            <th>Name</th>
                                            <th>E-mail</th>
                                            <th>Role</th>



                                        </tr>
                                    </thead>
                                    <tbody>



                                        {this.state.project.project_team.map((team_member, index) => {
                                            return (
                                                <tr><td>
                                                    <input type="checkbox" checked={false} onChange={this.handleInputChange} id={index} name={team_member.team_member_name} ></input>
                                                </td>
                                                    <td >{team_member.team_member_name}
                                                    </td>

                                                    <td> {team_member.user_ID}</td>
                                                    <td>{team_member.project_team_role}</td>

                                                </tr>
                                            )

                                        })

                                        }


                                    </tbody>

                                </table>






                            </div>


                        </div>








                        <div class="row">
                            <h3>Project Tasks</h3>
                            <div>  {


                                linkLijst.map((link) => {
                                    const aantalSelected = this.state.checkedtasks.length;
                                    if (aantalSelected >= link.min && aantalSelected <= link.max) {
                                        return (
                                            <Button variant="btn btn-primary" size="sm" value={link.link} id={link.taak} onClick={this.onComplete}  >{link.linkname}</Button>


                                        )
                                    }
                                    return (<div></div>)
                                })}
                            </div>
                            <div class="gantt-container">
                                <div class="gannt-chart" style={ganttChart}>

                                    <div class="item-row chart-row">
                                        <span>
                                            <input type="checkbox" checked={false} onChange={null}  ></input>
                                        </span>
                                        <span></span><span class="topnaam">Task name</span><span>Start</span>
                                        <span>End</span><span>Responsible</span>

                                        {this.getprojectMonths().map((maand, index) => {


                                            return (

                                                <maand key={index} style={{ 'grid-column': 'span ' + maand[1] }}>{maand[0]}</maand>
                                            )

                                        })

                                        }

                                    </div>

                                    {this.state.project.project_taken.map((taken, index) => {
                                        let status = "/" + taken.taak_status + ".png"
                                        let taakstart = moment(taken.taak_start_actual);
                                        let taakeind = moment(taken.taak_oplevering_actual);

                                        let kritiek = taken.taak_kritiek;

                                        let delay = taken.taak_delay_time;
                                        let lag = taken.taak_lag_time
                                        let delayStart = moment(taakeind).subtract(delay, 'days');
                                        let lagStart = moment(taakeind).subtract(lag, 'days')
                                        var telaatkleur = delay > 0 ? "red" : "white";

                                        return (

                                            <div class="item-row">
                                                <span>
                                                    <input type="checkbox" checked={taken.checked} onChange={this.handleTaskCheckbox} id={index} name={taken._id} ></input>
                                                </span>
                                                <span><img src={status} alt="0" height='25' /></span>
                                                <span class="naam">

                                                    <a href={'/updatetask/' + this.projectID + "/task/" + taken._id} data-toggle="popover" data-trigger="focus" title={taken.taak_name} >{taken.taak_name}</a>
                                                </span>
                                                <span>{taakstart.format("DD/MM/YYYY")}</span>
                                                <span style={{ backgroundColor: telaatkleur }}>{taakeind.format("DD/MM/YYYY")}</span>
                                                <span>{taken.taak_responsible}</span>

                                                {this.makeProjectDagen().map((dag, index) => {
                                                    var kleur;

                                                    if (dag.vandaag === true) { kleur = '#e6ffe6' }
                                                    if (moment(dag.datum).diff(taakstart, 'days') >= 0 && moment(dag.datum).diff(taakeind, 'days') <= 0) {

                                                        kritiek === true ? kleur = 'pink' : kleur = 'lightblue'
                                                    }
                                                    if (moment(dag.datum).diff(delayStart, 'days') > 0 && moment(dag.datum).diff(taakeind, 'days') <= 0) {

                                                        kritiek === true ? kleur = 'red' : kleur = 'blue'
                                                    }
                                                    if (moment(dag.datum).diff(lagStart, 'days') > 0 && moment(dag.datum).diff(taakeind, 'days') <= 0) {

                                                        kleur = '#F8F0E3';
                                                    }
                                                    if (dag.weekend === true) { kleur = '#f2dcd8' }
                                                    return (

                                                        <span key={index} style={{ backgroundColor: kleur }}></span>
                                                    )
                                                })
                                                }
                                            </div>

                                        )
                                    })
                                    }
                                </div>

                            </div>



                        </div>

                    </div>
                </div>
           </div>


        )
    }
}
