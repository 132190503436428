import React, { Component } from 'react';
import axios from 'axios';
import authService from '../services/auth.service';
import Navbar from './Navbar';

export default class CreateProject extends Component {

    constructor(props) {
        super(props);

        this.onChangeProjectStart = this.onChangeProjectStart.bind(this);
        this.onChangeProjectName = this.onChangeProjectName.bind(this);
        this.onChangeTemplate = this.onChangeTemplate.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.user = authService.getCurrentUser();
        this.state = {
            username: '',
            project_manager: {
                team_member_name: "",
                user_ID: "",
            },
            template: '0',
            templatelist: [],
            project_name: '',
            project_start: null,
            project_end: Date(),
            project_team: [],
            project_taken: [],

        }
    }

    componentDidMount() {

        if (this.user === 1) { return this.reDirect() }

        var userObject = { user: this.user }
        axios.post(authService.apiService() + 'templates', userObject
        )
            .then(response => {

                this.setState({
                    project_manager: {
                        team_member_name: response.data.username,

                        user_ID: response.data.email
                    },
                    templatelist: response.data.templates,
                    username: response.data.username,
                
                });
            })
            .catch(function (error) {
                console.log(error);
            })

    }

    reDirect() {
        this.props.history.push('login');
    }

    onChangeProjectStart(e) {
        this.setState({
            project_start: e.target.value
        });
    }

    onChangeProjectName(e) {
        this.setState({
            project_name: e.target.value
        });
    }
    onChangeTemplate(e) {
        if (e.target.value !== "0") {
            var userObject = { user: this.user }
            axios.post(authService.apiService() + e.target.value + '/project', userObject)
                .then(response => {
                    var project = response.data;
                    this.setState({
                        project_team: project.project_team,
                        project_taken: project.project_taken
                    });
                })
                .catch(function (error) {
                    console.log(error);
                })

        }

        this.setState({
            template: e.target.value
        });
    }


    onSubmit(e) {
        e.preventDefault();



        const newProject = {
            user: this.user,
           
            project: {
                project_name: this.state.project_name,
                project_start: this.state.project_start,
                project_end: this.state.project_end,
                project_team: this.state.project_team,
                project_taken: this.state.project_taken
            }
        };

        axios.post(authService.apiService() + 'add', newProject)
            .then(res => console.log(res.data));


        window.location.replace('/');
    }

    render() {
        return (
            
               
                <div class="container" >
                   
                    <div>
                        <div class="container" style={{ marginTop: 10 }}>
                            <h1>Create Project</h1>
                            <div >
                                <form onSubmit={this.onSubmit} className="mb-4">

                                    <div className="form-group row" style={{ marginTop: 5 }}>
                                        <label class="col-sm-4 col-form-label">Template </label>
                                        <div class="col-sm-4">
                                            <select

                                                type="text"
                                                className="form-select"

                                                onChange={this.onChangeTemplate}
                                            >


                                                {
                                                    this.state.templatelist.map((template) => {



                                                        return (
                                                            <option value={template.id} >{template.name}</option>
                                                        )

                                                    })

                                                }
                                            </select>

                                        </div>


                                    </div>

                                    <div className="form-group row" style={{ marginTop: 5 }}>
                                        <label class="col-sm-4 col-form-label">Project name: </label>
                                        <div class="col-sm-4">
                                            <input type="text"
                                                placeholder="Project name"
                                                className="form-control"
                                                value={this.state.project_name}
                                                onChange={this.onChangeProjectName}

                                            />
                                        </div>

                                    </div>


                                    <div className="form-group row" style={{ marginTop: 10 }}>
                                        <label class="col-sm-4 col-form-label">Project start: </label>
                                        <div class="col-sm-4">
                                            <input
                                                type="date"
                                                className="form-control"
                                                value={this.state.project_start}
                                                onChange={this.onChangeProjectStart}
                                            />
                                        </div>
                                    </div>


                                    <div className="form-group">
                                        <input type="submit" value="Create Project" className="btn btn-primary" style={{ marginTop: 5 }} />
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            

        )
    }
}