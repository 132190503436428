import axios from "axios";

const API_URL = "https://jekt.nl/projects/";
//dev: http://localhost:4000/projects/
// server: https://jekt.nl/projects/

class AuthService {
  async login(username, password) {
    const response = await axios
      .post(API_URL + "signin", {
        username,
        password
      });
    if (response.data.accessToken) {
      localStorage.setItem("user", JSON.stringify(response.data));
    }
    return response.data;
  }

  logout() {
    localStorage.removeItem("user");
  }

  apiService(){
    return API_URL;
  }

  register(username, email, password) {
    return axios.post(API_URL + "signup", {
      username,
      email,
      password
    });
  }

  getCurrentUser() {
var usercode = localStorage.getItem('user');
if (usercode === null){usercode = 1};

    return JSON.parse(usercode);
  }
}

export default new AuthService();