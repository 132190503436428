
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import axios from 'axios';
import '../agenda.css'







export default function Agenda(props) {
    const dagStart = new Date(props.dagStart)
    const dagEind = new Date(props.dagEind)
    const dagen = (dagEind / (24 * 60 * 60 * 1000)) - (dagStart / (24 * 60 * 60 * 1000));
    const uren = ["00:00", "01:00", "02:00", "03:00", "04:00", "05:00", "06:00", "07:00", "08:00", "09:00", "10:00", "11:00", "12:00", "13:00", "14:00", "15:00", "16:00", "17:00", "18:00", "19:00", "20:00", "21:00", "22:00", "23:00"];
    const start = uurNaarRij(props.actieplan)
    const end = start + Math.round(parseInt(props.duur) / 10)
    const [isDragging, setIsDragging] = useState(false);


    useEffect(() => {
        var target = document.getElementById("07:00")
        target.parentNode.scrollTop = target.offsetTop - target.parentNode.offsetTop;
        uurNaarRij(props.actieplan)
        

        
    }

        , [])


    function dagenlijst() {
        var daglijst = [];
        for (var i = 0; i < dagen + 1; i++) {
            daglijst.push(new Date(new Date(dagStart).setDate(dagStart.getDate() + i)))
        }
        return daglijst;
    };

    function handleDrop(e) {
        const dataJSON = e.dataTransfer.getData("text/plain");
        let data;
        try {
            data = JSON.parse(dataJSON);
        } catch { }
        if (data && data.type === "start") {


        }
        if (data && data.type === "end") {
            props.setDuur(Math.abs((parseInt(e.target.dataset.rij) * 10) - (start - 1) * 10))
            props.onChange(true)
        }
    }



    function handleDragLeave(e) {
        let element = document.getElementById(e.target.id);
        element.style.backgroundColor = null
        element.style.borderBottom = null

    }

    function uurNaarRij(datumtekst) {
        var datum = new Date(datumtekst)
        var midnight = new Date(datum).setHours(0, 0, 0, 0)
        var minutes = (datum - midnight) / 60 / 1000

        return Math.round(minutes / 10);



    }


    function handleDragStart(e) {

        setIsDragging(true);


        const data = JSON.stringify({ type: e.target.id })
        e.dataTransfer.setData("text/plain", data);
    }

    function handleDragOver(e) {

        if (e.dataTransfer.types[0] === "text/plain") {
            const dataJSON = e.dataTransfer.getData("text/plain");
            let element = document.getElementById(e.target.id);
            element.style.borderBottom = "2px solid blue"



            e.preventDefault();





        }
    }

    function nu() {
        var nu = new Date()
        var afgerondNu = Math.round(nu.getMinutes()/10)*10
        nu.setMinutes(afgerondNu,0,0)
        
       return nu
        
    }
    function handleMouseOver(e) {


        let element = document.getElementById(e.target.id);
        element.style.backgroundColor = "lightblue"

     


    }
    function handleHide() {
        let element = document.getElementById('start');


        element.classList.add('hide');
    }


    function handleDragEnd(e) {
        setIsDragging(false);
        let element = document.getElementById('start');


        element.classList.remove('hide');
        e.dataTransfer.clearData();
    }



    function makeAgendaUren() {
        var agendadagen = []

        dagenlijst().forEach((dag) => {

            var agendaUren = [];


            for (var i = 0; i < 144; i++) {
                dag.setHours(0, 0, 0, 0)
                var pushuur = new Date(new Date(dag).setMinutes(dag.getMinutes() + (i * 10)))


                agendaUren.push(
                    pushuur
                )
            }
            agendadagen.push(agendaUren)
        })

        return agendadagen

    }


    function Uur({ dag, tijd, index }) {
        var gelijk = new Date(tijd).getMinutes()
     
        var topborder = gelijk === 0 ? "1px solid blue" : tijd.toString() === nu().toString()?"2px solid red" : ''
        
        
        
        
        return (

            <div className="uur" id={tijd} data-rij={index} data-kolom={dag} data-tijd={tijd} style={{
                "grid-column-start": (dag + 2).toString(),

                "grid-row-start": (index + 2).toString(),
                "borderLeft": "1px solid blue",
                "borderTop": topborder,
              
                "opacity": 0.5






            }
            } onMouseOver={handleMouseOver}
                onDrop={handleDrop}
                onMouseLeave={handleDragLeave}
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                onClick={() => { props.setStart(tijd); props.onChange(true) }}

            >

            </div>
        );
    }

    let tekst = ""; for (let dag in dagenlijst()) { tekst += '[x' + moment(dagenlijst()[dag]).format("DDMMYYYY") + '] 150px ' };

    const kolommenstyle = { 'grid-template-columns': '[uren] 3em' + tekst }


    return (




        <div className='agenda-container rounded' style={kolommenstyle} >
            {dagenlijst().map((dag, index) => {
                return (<div className='dag' style={{ "grid-column": (index + 2).toString() }}>

                    <div className='col' style={{

                    }}>{moment(dag).format("dddd ")}</div>
                    <strong className='col'>
                        {moment(dag).format("DD MMMM")}</strong>


                </div>)
            })}

            {uren.map((uur, index) => {
                return (
                    <div className='uurnummer' id={uur} style={{
                        'grid-column-start': 'uren',

                        'grid-row-start': (((index + 1) * 6) - 4).toString(),




                    }}> {uur}</div>
                )
            })}

            {makeAgendaUren().map((dag, index) => {
                return (dag.map((tijd, idx) => {
                    return (
                        <Uur
                            dag={index}
                            tijd={tijd}
                            index={idx}

                        />
                    )

                }))

            })}

            {props.agendaitems && props.agendaitems.map(({ _id, actie_start, duur, omschrijving, done }) => {
               var achtergrond = done ? 'green' : 'blueviolet'

                return (<div className='actie' id='item' style={{
                    "grid-column-start": "x" + moment(actie_start).format("DDMMYYYY"),

                    "grid-row-start": (uurNaarRij(actie_start) + 2).toString(),
                    "grid-row-end": ((uurNaarRij(actie_start) + 2) + duur / 10).toString(),
                    backgroundColor: achtergrond,
                    color: "white",
                    opacity: "0.5",
                    border: "none"
                }}
                    onClick={() => window.open('/deliverable/' + _id.toString(), '_self')}
                >  {omschrijving}



                </div>)
            })}



            <div className='actie' id='start' style={{
                "grid-column-start": "x" + moment(props.actieplan).format("DDMMYYYY"),

                "grid-row-start": (start + 2).toString(),
                "grid-row-end": (end + 2).toString(),
                backgroundColor: isDragging ? "#fbb" : "palegoldenrod",
                opacity: "0.6"
            }}
               onDragStart={handleDragStart}
                onDragEnd={handleDragEnd}
                onDragOver={handleHide}
            >  {props.omschrijving}


                <div className='actie-handle' id="end" draggable

                    style={{



                    }}></div>
            </div>
        </div>





    )






}
