
import React, { useEffect, useState } from 'react';
import Form from "react-validation/build/form";
import CheckButton from "react-validation/build/button";
import Input from "react-validation/build/input";
import Select from "react-validation/build/select"
import Button from 'react-bootstrap/Button'
import authService from '../services/auth.service';
import Menu from './menu.component';
import axios from 'axios';
import Agenda from './agenda.component';
import moment from 'moment';
import Collapse from 'react-bootstrap/Collapse'
import Deliverable from './deliverable.component';
import '../agenda.css';
import Dropdown from 'react-bootstrap/Dropdown'
import DropdownButton from 'react-bootstrap/DropdownButton';


export default function Action(props) {

    const acties =['action','signature','tekst','link']
    const actielijst = [{ text: 'Action', 'action': '' }, { text: 'Sign off', 'signature': '' }, { text: 'Text', 'tekst': '' }, { text: "Link" }]
    const [user, setUser] = useState("Login");
    const [newaction, setNewAction] = useState(false);
    const [hasParent, setHasParent] = useState(false)
    const [changed, setChanged] = useState(false);
    const [planstart, setPlanStart] = useState(null);
    const [planend, setPlanEnd] = useState(null);
    const [open, setOpen] = useState(false)




    const [soort, setSoort] = useState(0);
    const [deliverableWaarde, setDeliverableWaarde] = useState(JSON.stringify({ "action": "" }))





    const [status, setStatus] = useState(0)

    const [actie, setActie] = useState({
        omschrijving: "",
        veld: "",
        responsible: null,
        duur: 20,
        actie_start: null,
        done: false,
        status: 0
    })










    var actionform;


    const [rij, setRij] = useState(0)

    const [agendaitems, setAgendaItems] = useState([])
    const imagelist = [{ image: '/0.png', tekst: 'Waiting' }, { image: '/1.png', tekst: 'Planned' }, { image: '/2.png', tekst: 'Complete' }]
    const required = value => {
        if (!value) {
            return (
                <div className="alert alert-danger" role="alert">
                    This field is required
                </div>
            );
        }
    };



    const adressen = ["frans@iambored.nl", "f.willems14@gmail.com"]
    const reDirect = () => {
        props.history.push('/login');
    }
    const checkuser = authService.getCurrentUser();
    useEffect(() => {

        var start;
        var end;

        if (checkuser === 1) { return reDirect() }

        var userObject = { user: checkuser }

        axios.post(authService.apiService() + "deliverable/" + props.match.params.id, userObject)
            .then(response => {
                
                setUser(response.data.username)
                setNewAction(response.data.newaction)
                
                response.data.planstart === undefined ? start = moment(new Date().setHours(0, 0, 0, 0)) : start = response.data.planstart
                response.data.planend === undefined ? end = moment(start).add(7, 'days') : end = response.data.planend
                response.data.deliverable.duur === undefined ? setDuur(20) : setDuur(response.data.deliverable.duur)
                
                setHasParent(response.data.parent);
                if (response.data.deliverable !== undefined) {
                    setActie(response.data.deliverable);
                    var veld = response.data.deliverable.veld??JSON.stringify({ "action": "" })
                    setDeliverableWaarde(veld)
                    var deliverable = JSON.parse(veld);
                    var deliverablenaam = Object.keys(deliverable)[0]
                    
                   
                    setSoort(acties.indexOf(deliverablenaam))
                  

                }

                setStatus(response.data.deliverable.status)
                setAgendaItems(response.data.agendaitems)
                setPlanStart(moment(start).format('yyyy-MM-DD'))
                setPlanEnd(moment(end).format('yyyy-MM-DD'))





            })
            .catch((error) => {

                console.log(error)
                reDirect();




            })



    }, []);

    function setStatusEnRij(rij) {
        
        setStatus(1)
        /*     var setaction = { ...actie }
            setaction["status"] = 1
            setActie(setaction) */
    }
    function setActiePlan(datum) {
        var setaction = { ...actie }
        setaction["actie_start"] = datum
        
        setActie(setaction)
    }

    function deleteAction(){
        if (checkuser === 1) { return reDirect() }
        var userObject = { user: checkuser }

        
        axios.post(authService.apiService() + "deleteaction/" + props.match.params.id, userObject)
            .then(
                props.history.replace('/')
            )
}
    function onChangeDone() {
        
        var setaction = { ...actie }

        setStatus(() => {
            if (!actie.done === true) { return 2 }
            if (status > 0 && actie.actie_start) { return 1 }
            return 0


        })
        setaction["status"] = status

        setaction["done"] = !actie.done
        setActie(setaction)
        setChanged(true)


    }

    function setDuur(minuten) {
        var setaction = { ...actie }
        setaction["duur"] = minuten
        setActie(setaction)


    }

    function changeDeliverable(e) {

        var deliverable = JSON.parse(deliverableWaarde);
        var tekst = Object.keys(deliverable)[0]

        var updateData = e.target.value
        var updateObject = {
            [tekst]: updateData
        }

        setChanged(true)
        setDeliverableWaarde(JSON.stringify(updateObject));
       
    }

    function changeStart(e) {
        var start = moment(new Date(e.target.value)).startOf('day');
        var end;
        var duur = Math.abs(moment(planstart).diff(moment(planend), 'days'))
        end = start.add(duur, 'days').format('yyyy-MM-DD')
        var startstring = e.target.value


        setPlanStart(startstring)
        setPlanEnd(end)

    }
    function changeSoort(e) {
        setSoort(e.target.value)
        var deliverObject = actielijst[parseInt(e.target.value)];
        var deliverSoort = Object.keys(deliverObject)[1]
        var deliverData = deliverObject[deliverSoort]


        var updateObject = {
            [deliverSoort]: deliverData
        }

        setDeliverableWaarde(JSON.stringify(updateObject));
    }
    function deliverable() {
        var initDeliverable = deliverableWaarde;


        const deliverable = JSON.parse(initDeliverable)



        const veld = Object.keys(deliverable)[0];
        const data = deliverable[veld];



        return {
            veld: veld,
            data: data
        }


    }

    function onChangeAction(e) {

        e.preventDefault();
        var setaction = { ...actie }
        setaction[e.target.name] = e.target.value
        setActie(setaction)
    }



    function handleRegister(e) {
        e.preventDefault();
        actionform.validateAll();
        if (actioncheckBtn.context._errors.length === 0||actie.done) {


            const newAction = {
                ...actie,
                status: status,
                veld: deliverableWaarde ?? JSON.stringify({ "action": "" })
            }


            if (checkuser === 1) { return reDirect() }

            var sendTask = { user: checkuser, actie: JSON.stringify(newAction) }
            axios.post(authService.apiService() + "saveaction/" + props.match.params.id, sendTask)
                .then(response => {
                    window.open('/deliverable/' + response.data.actieid, '_self');
                })
        }
    }


    var actioncheckBtn;
    return (


        <div className="indexcontainer">
            <div><Menu user={user} /></div>
            <div style={{ margin: '1em' }}>









                <div class="d-flex justify-content-between">
                    <h2>{}</h2>
                    
                    <DropdownButton

                            key={'success'}
                            id={`dropdown-variants-success`}
                            variant={'success'}
                            title='Options'
                        >
                            <Dropdown.Item eventKey="3" onClick={() => setNewAction(true)}>Edit</Dropdown.Item>
                            
                            <Dropdown.Divider />
                           <Dropdown.Item eventKey="3" onClick={() => deleteAction()}>Delete deliverable</Dropdown.Item>
                        </DropdownButton>
                </div>


                <div class="container" style={{ marginTop: 30 }} >


                    <div className="col m-1">



                        <Form noValidate

                            onChange={setChanged}
                            onSubmit={handleRegister}

                            ref={c => {
                                actionform = c;
                            }} >{!actie.done&&<div className="col m-3 p-3 rounded shadow" style={{ backgroundColor: "whitesmoke" }}>

                                <div className="form-group row" style={{ marginTop: 15 }}>
                                    <label class="col-sm-3 col-form-label">Status: </label>
                                    <div class="col-sm-1" >
                                        <img src={imagelist[status??0].image} alt={imagelist[status??0].tekst} height="30"></img>
                                    </div><div class="col-sm-2" >
                                        <label style={{ marginLeft: 5, marginRight: 20 }}>  {imagelist[status??0].tekst}</label>
                                    </div>

                                </div>
                                <div className="form-group row" style={{ marginTop: 15 }}>
                                    <label class="col-sm-3 col-form-label">Description: </label>
                                    <div class="col-sm-7" >
                                        <Input
                                            disabled={!newaction}
                                            type="text"
                                            className="form-control"
                                            name="omschrijving"
                                            placeholder="fill in deliverable description..."
                                            value={actie.omschrijving}
                                            onChange={onChangeAction}
                                            validations={[required]}
                                        />
                                    </div>

                                </div>
                                <div className="form-group row" style={{ marginTop: 15 }}>
                                    <label class="col-sm-3 col-form-label">Responsible: </label>
                                    <div class="col-sm-7">
                                        <Select
                                            type="text"
                                            className="form-select"
                                            name='responsible'
                                            disabled={!newaction}
                                            value={actie.responsible}
                                            
                                            onChange={onChangeAction}
                                            validations={[required]}
                                        >
                                            <option value="" disabled selected hidden>Choose responsible...</option>
                                            {
                                                adressen.map((email) => {

                                                    return (<option value={email}>{email}</option>)
                                                })
                                            }
                                        </Select>
                                    </div>
                                </div>
                            </div>}
                            {!actie.done&&<div class="col m-3 p-3 rounded shadow" style={{ backgroundColor: "whitesmoke" }}>

                                <div className="form-group row " style={{ marginTop: 15 }}>
                                    <label class="col-sm-3 col-form-label">Plan: </label>
                                    <div class="col-sm-7">
                                        <Input
                                            type="text"
                                            className="form-control"

                                            placeholder='Plan in agenda...'
                                            disabled={true}
                                            value={actie.actie_start && moment(actie.actie_start).format('dddd, DD MMMM YYYY HH:mm') + ' - ' + moment(actie.actie_start).add(actie.duur, 'minutes').format('HH:mm')}

                                            onChange={e => { setActiePlan(e.target.value) }}
                                     
                                        >


                                        </Input>
                                    </div>




                                    <div className='col'>
                                        <Button
                                            variant="success shadow-sm"
                                            onClick={() => { setOpen(!open) }}


                                            aria-controls="collapse"
                                            aria-expanded={open}
                                        >
                                            {open ? "Hide agenda" : "Show agenda"}
                                        </Button>
                                    </div>
                                </div>
                                <Collapse in={open} onEntered={() => { document.getElementById("11:00").scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" }) }} >




                                    <div className='collapse'>
                                        {<div className="form-group row" style={{ marginTop: 15 }}>
                                            <label class="col-sm-3 col-form-label">Plan between:</label>
                                            <div class="col-sm-3" >
                                                <Input

                                                    type="date"
                                                    className="form-control"


                                                    value={planstart}
                                                    onChange={changeStart}
                                                    validations={[required]}
                                                />
                                            </div>
                                            <label class="col-sm-1 col-form-label">and: </label>
                                            <div class="col-sm-3" >
                                                <Input

                                                    type="date"
                                                    className="form-control"
                                                    name="duur"
                                                    min="0"
                                                    value={planend}
                                                    onChange={e => setPlanEnd(e.target.value)}
                                                    validations={[required]}
                                                />
                                            </div>

                                        </div>}

                                        <div className="form-group row" style={{ marginTop: 15 }}>
                                            <label class="col-sm-3 col-form-label">Duration (minutes):</label>
                                            <div class="col-sm-7" >
                                                <Input
                                                    disabled={!newaction}
                                                    type="number"
                                                    className="form-control"
                                                    name="duur"
                                                    min="0"
                                                    value={actie.duur}
                                                    onChange={onChangeAction}
                                                    validations={[required]}
                                                />
                                            </div>

                                        </div>

                                        <div className='col m-3' >
                                            <Agenda
                                                duur={actie.duur}
                                                onChange={setChanged}
                                                setDuur={setDuur}
                                                actieplan={actie.actie_start}
                                                rij={rij}
                                                setRij={setStatusEnRij}
                                                agendaitems={agendaitems}
                                                newplan={newaction}
                                                setStart={setActiePlan}
                                                omschrijving={actie.omschrijving}
                                                dagStart={planstart}
                                                dagEind={planend}
                                            />
                                        </div>
                                    </div>
                                </Collapse>



                            </div>}
                            <div class="col m-3 p-3 rounded shadow" style={{ backgroundColor: "whitesmoke" }}>
                                <div className="form-group row" style={{ marginTop: 15 }}>
                                    <label class="col-sm-3 col-form-label mt-3">Deliverable: </label>
                                    <div class="col-sm-7 mt-3">

                                        <select
                                         disabled={!newaction}
                                            type="text"
                                            className="form-select"
                                            value={soort}

                                            onChange={changeSoort}
                                        >

                                            {
                                                actielijst.map((actie, index) => {

                                                    return (<option value={index}>{actie.text}</option>)
                                                })
                                            }
                                        </select></div>
                                    <div className='col'>
                                        <Button
                                             
                                            variant={actie.done ? "success shadow-sm mt-3" : "warning shadow-sm mt-3"}
                                            onClick={() => onChangeDone(!actie.done)}

                                        >
                                            {actie.done ? "Ready" : "Mark as complete"}
                                        </Button>


                                    </div>
                                    <div class="col-sm-12 mt-3">

  
        <Deliverable veld={deliverable().veld} value={deliverable().data} onAdd={changeDeliverable}  responsible={actie.responsible} disabled={actie.done}></Deliverable>
                                    </div>
                                </div>


                            </div>
                            {changed && <div className=''>
                                <div className='d-flex justify-content-end'>
                                    <Button variant="success m-1" type='submit'  >
                                        Save changes
                                    </Button>
                                    <Button variant="outline m-1" onClick={()=>window.history.back()} >
                                        Close
                                    </Button>
                                </div >
                            <div className="m-3"></div></div>}
                            <CheckButton
                                style={{ display: "none" }}
                                ref={c => {
                                    actioncheckBtn = c;
                                }}
                            />

                        </Form>


                    </div>

                </div>


            </div>
        </div>


    )






}
