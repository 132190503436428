import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import React, { Component } from 'react';
import logo from "../components/logo192.png";
import Button from 'react-bootstrap/Button'
import "../index.css"
import authService from "../services/auth.service";
import MobileBar from "./MobileBar";

export default class Navbar extends Component {



  onLogOut(){
    authService.logout();
    window.open('/', '_self')
  }
 



    render() {
      return (
       <div>
            <nav className="navbar navbar-expand-lg navbar-dark bg-dark ">
            <div class="container">

           
            <a class="navbar-brand" href="https://www.jekt.nl">
              <img src={logo} width="30" height="30" alt="JEKT.nl" />
            </a>
            <a className="navbar-brand" href="/">JEKT.nl</a>
            
            <div className="collpase navbar-collapse">
              <ul className="navbar-nav mr-auto">
                <li className="navbar-item">
                  <a href="/" className="nav-link">You never work alone</a>
                </li>
               
              </ul>
            </div>
           
      
            {(  
             <ul class="nav navbar-nav navbar-right">
            
            <li><Button variant="nav nav-link"   onClick={this.onLogOut}>Sign out </Button></li>
             <li><a href="/profile" className="nav-link">{this.props.user}</a></li>
      
    </ul>
            )}
           
     
            </div> 
          </nav>
    
        </div>

       ) }
}


