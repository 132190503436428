import React, { Component } from 'react';
import "../index.css"
import axios from 'axios';
import moment from 'moment';
import ProgressBar from 'react-bootstrap/ProgressBar'
import authService from '../services/auth.service';
import Menu from './menu.component';
import Agenda from './agenda.component'


//deze pagina is een overzicht van de organisatie, taken en projecten van degene die is ingelogged

export default class OverView extends Component {


    constructor(props) {
        super(props);



        this.state = {
            username: "Profile",
            totaal_taken: 0,
            taken_complete: 0,
            taken_start: 0,
            taken_wait: 0,
            period_start: "",
            period_end: "",
            agendaitems:[]
        };

    }


    componentDidMount() {


        this.projectID = this.props.match.params.id;
        const user = authService.getCurrentUser();
        if (user === 1) { return this.reDirect() }
        var userObject = { user: user }

        axios.post(authService.apiService() + '/overview', userObject)
            .then(response => {



                this.setState({

                    username: response.data.username,
                    totaal_taken: response.data.totaal_taken,
                    taken_complete: response.data.taak_status[2],
                    taken_start: response.data.taak_status[1],
                    taken_wait: response.data.taak_status[0],
                    period_start: response.data.periode_start,
                    period_end: response.data.periode_end,
                    agendaitems: response.data.agendaitems
                });


            })
            .catch(function (error) {

            })

    }
    reDirect() {
        this.props.history.push('/login');
    }







    render() {

        return (

            <div className="indexcontainer">
                <div><Menu user={this.state.username} /></div>
                <div style={{ margin: '1em' }}>
                    <div class="d-flex justify-content-between">
                        <h2>Overview:{' ' +moment(this.state.period_start).format("DD-MM-YYYY")}{" - "}{moment(this.state.period_end).format("DD-MM-YYYY")}</h2>
                        <button type="button" class="btn btn-success shadow-sm">Profile</button>
                    </div>


                    <div class="container" style={{ marginTop: 30 }} >

                        <div class='row'>
                            <div class="col-md-4">


                                <div class="col rounded shadow">
                                    <div style={{ padding: 15 }}>
                                        <h3>Deliverables</h3>
                                        <p>Completed:</p>
                                        <p>Planned:</p>
                                        <p></p>

                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">


                                <div class="col rounded shadow">
                                    <div style={{ padding: 15 }}>
                                        <h3>Tasks</h3>
                                        <table className="table" style={{ marginTop: 20 }} >
                                            <tbody>
                                                <tr>
                                                    <td>Total:</td>
                                                    <th>
                                                        {this.state.totaal_taken}

                                                    </th>
                                                </tr>
                                                <tr>
                                                    <td>Completed:</td>
                                                    <th >
                                                        {this.state.taken_complete}

                                                    </th>
                                                </tr>
                                                <tr>
                                                    <td>Start: </td>
                                                    <th>
                                                        {this.state.taken_start}

                                                    </th>
                                                </tr>
                                                <tr>
                                                    <td>Waiting: </td>
                                                    <div style={{ "width": 150 }}>
                                                        <ProgressBar>
                                                            <ProgressBar variant="success" min='0' max='100' now={20} />
                                                            <ProgressBar variant="warning" min='0' max='100' now={40} />
                                                            <ProgressBar variant="danger" min='0' max='100' now={40} />
                                                        </ProgressBar>

                                                    </div>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-4">


                                <div class="col rounded shadow">
                                    <div style={{ padding: 15 }}>
                                        <h3>Projects</h3>
                                        <p>Ongoing:</p>
                                        <p>Waiting:</p>
                                        <p>Start:</p>

                                    </div>
                                </div>
                            </div>



                        </div>
                        <div className="col m-1">


                            <div class="col rounded shadow" style={{ "background-color": "whitesmoke", padding: 15, margin: 10 }}>

                                <Agenda



                                    setStart={(tijd) => {console.log(tijd) }}
                                    setRij={() => { }}
                                    onChange={() => { }}
                                    agendaitems={this.state.agendaitems}


                                    


                                    dagStart={this.state.period_start}
                                    dagEind={this.state.period_end}
                                />

                            </div>

                        </div>

                    </div>


                </div>
            </div>



        )
    }
}
