import React, { useState, useEffect } from 'react';

import axios from 'axios';
import moment from 'moment';
import authService from '../services/auth.service';
import Menu from "./menu.component";
import Button from 'react-bootstrap/Button'
import Input from "react-validation/build/input";

import Form from "react-validation/build/form";
import CheckButton from "react-validation/build/button";
import Dropdown from 'react-bootstrap/Dropdown'
import DropdownButton from 'react-bootstrap/DropdownButton';
import "../index.css"
import ActionTile from './actionTile.component';
import ModalForm from './modal.component'



const required = value => {
    if (!value) {
        return (
            <div className="alert alert-danger" role="alert">
                This field is required
            </div>
        );
    }
};


const actielijst = [{ text: 'Action', 'action': '' }, { text: 'Sign off', 'signature': '' }, { text: 'Text', 'tekst': '' }, { text: "Link" }]

export default function Task(props) {


    const [newtask, setNewtask] = useState(false)
    const [info, setInfo] = useState({ name: "", email: "" })
    const [username, setUserName] = useState("Login")
    
    const [showactionform, setShowActionForm] = useState(false)
    const [showinfoform, setShowInfoForm] = useState(false)
    const [currentrow, setCurrentRow] = useState(0)
    const [currenttile, setCurrentTile] = useState(0)
    const [actionform, setActionForm] = useState({
        omschrijving: "",
        responsible: "Select",
        duur: 20,
        status: 0,
        soort: "Action",
        actiongroup: 0
    })
    const user = authService.getCurrentUser();
    const [actiongroups, setActionGroups] = useState([[{ omschrijving: "Mark task as completed", actiongroup: 0, responsible: "", new:false , duur: 20, soort: "Action"}]]);
    const flatActions = actiongroups.flat(Infinity)
    const totalActions = flatActions.length
    const finishedActions = flatActions.reduce((count, action) => count + (action.status === 2 ? 1 : 0), 0);
    
    const [recurrence , setRecurrance]= useState({herhaal: 'Once'});
    const [taak, setTaak] = useState({
        taak_responsible: "",
        taak_name: "",
        parent: "",
        taak_oplevering_plan: '',
        taak_duur: null,
        taak_status: 0,
        taak_log: [],

        taak_informed: [],
    })
    const [adressen, setAdressen] = useState(["frans@iambored.nl", "f.willems14@gmail.com"])

    useEffect(() => {

        if (user === 1) { return reDirect() }

        var userObject = { user: user }

        axios.post(authService.apiService() + "task/" + props.match.params.tasknumber, userObject)
            .then(response => {

                var responsetaak = response.data.taak
                if (responsetaak === undefined) {
                    setNewtask(true);
                    setTaak({...taak})
                } else {
                    setTaak(responsetaak)
                    setActionGroups(response.data.actiongroups)
                   
                }
                setUserName(response.data.username, [])
               
              

            })
            .catch((error) => {
                console.log(error)
                reDirect();
            })
    },[]);



    function reDirect() {
        props.history.push('/login');
    }

    function addActionGroup(e) {
        var newGroup = [{ omschrijving: "", actiongroup: 0, responsible: "" }]
        var actionlist = actiongroups;
        actionlist.forEach((actielijst) => {
            actielijst.forEach((actie) => {
                actie.actiongroup++
            })
        })
        //actionlist.splice(e.target.value, 0, newGroup)
        setActionGroups([newGroup, ...actionlist])
    }
    function onChangeActie(e) {

        e.preventDefault();
        var tijdelijkObject = { ...actionform }
        tijdelijkObject[e.target.name] = e.target.value;
        tijdelijkObject['actiongroup'] = currentrow ?? 0;
        setActionForm(tijdelijkObject)
    }

    function onChangeInform(e) {

        e.preventDefault();
        var inform = { ...info }
        inform[e.target.name] = e.target.value
        setInfo(inform)
    }

    function addInformed(e) {

        e.preventDefault();
        infoform.validateAll();
        if (infocheckBtn.context._errors.length === 0) {
            var settaak = taak
            settaak.taak_informed.push({ nickname: info.name, infoemail: info.email })
            var sendlist = { status: JSON.stringify(settaak.taak_informed) }


            axios.post(authService.apiService() + "emailstatus", sendlist)
                .then(response => {
                    
                    settaak.taak_informed = response.data.status
                    setTaak(settaak)
                    setShowInfoForm(false)
                    setInfo({name:'', email:''})
                });
        }
    }


    function addAction() {

        

        actionformulier.validateAll();
        if (actioncheckBtn.context._errors.length === 0) {
            var changelist = [...actiongroups]
            var addAction = actionform
            addAction.actiongroup = currentrow
            if (actionform.new){
            changelist[currentrow].splice(currenttile, 0, addAction);
            }
            else{
                changelist[currentrow][currenttile] = addAction
            }
           setActionGroups(changelist)
           setShowActionForm(false)
           
           
        }
    }

    function onChangeTask(e) {

        e.preventDefault();
        var settaak = { ...taak }
        settaak[e.target.name] = e.target.value
        if(e.target.name==="taak_responsible"){actiongroups[actiongroups.length-1][actiongroups.length-1].responsible = e.target.value }
        setTaak(settaak)
    }

    function setCurrent(e) {

        var rij = parseInt(e.target.dataset.rij)
        var tile = parseInt(e.target.dataset.tile)
        e.preventDefault();
        setCurrentRow(rij);
        setCurrentTile(tile);
        var setform = actiongroups[rij][tile]
        if(newtask){
        
        setActionForm({
            omschrijving: setform.omschrijving,
            responsible: setform.responsible,
            duur: setform.duur,
            soort: setform.soort,
            new: false
        })
        setShowActionForm(true)}
        else{
            props.history.push('/deliverable/' + setform._id.toString())
        }

        
    }

    function handleRegister(e) {
        e.preventDefault();
        form.validateAll();
        if (checkBtn.context._errors.length === 0) {
            var acties = [];
            actiongroups.forEach((actionlist) => {
                actionlist.forEach((action) => {
                    action.veld = JSON.stringify({[action.soort]:''})
                    acties.push(action)
                })
            })
            const newTask = {
                ...taak,
                acties: acties,
            }


            if (user === 1) { return reDirect() }

            var sendTask = { user: user, taak: JSON.stringify(newTask) }
            axios.post(authService.apiService() + "savetask/" + props.match.params.tasknumber, sendTask)
                .then(response => {
                    window.open('/task/' + response.data.taakid, '_self');
                })
        }
    }

    var taaknummer;
    var actionformulier;
    var actioncheckBtn;
    var form;
    var checkBtn;
    var infoform;
    var infocheckBtn

    newtask ? taaknummer = 'Schedule task' : taaknummer = props.match.params.tasknumber;

    return (

        <div className="indexcontainer">
            <div><Menu user={username} /></div>
            <div class='container' style={{ 'padding-top': '1em' }} >


                <div class="d-flex justify-content-between">
                    <h2>Task</h2>
                    <>

                        <DropdownButton

                            key={'success'}
                            id={`dropdown-variants-success`}
                            variant={'success'}
                            title='Options'
                        >
                            <Dropdown.Item eventKey="3" onClick={() => setNewtask(true)}>Edit</Dropdown.Item>
                            <Dropdown.Divider />
                            {newtask && <>


                                <Dropdown.Item eventKey="1" onClick={addActionGroup}>Add Action group</Dropdown.Item></>}
                        </DropdownButton>


                    </>

                </div>
                <div class="container" style={{ marginTop: 30 }} >
                    <ModalForm show={showactionform}
                        header='Add Action '
                        children={
                            <div className="form-group row" style={{ marginTop: 15 }}>
                                <Form noValidate
                                    data-form="actionshow"
                                    ref={c => {
                                        actionformulier = c;
                                    }} >
                                    <div className="form-group row" style={{ marginTop: 15 }}>
                                        <label class="col-sm-4 col-form-label">Deliverable: </label>
                                        <div class="col-sm-6">

                                            <select
                                                type="text"
                                                className="form-select"
                                                value={actionform.soort}
                                                name={'soort'}
                                                onChange={onChangeActie}
                                            >

                                                {
                                                    actielijst.map((actie) => {
                                                        return (<option value={Object.keys(actie)[1]}>{actie.text}</option>)
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    <div className="form-group row" style={{ marginTop: 15 }}>
                                        <label class="col-sm-4 col-form-label">Description: </label>
                                        <div class="col-sm-6" >
                                            <Input
                                                autoFocus
                                                type="text"
                                                className="form-control"
                                                name="omschrijving"

                                                value={actionform.omschrijving}
                                                onChange={onChangeActie}
                                                validations={[required]}
                                            />
                                        </div>

                                    </div>
                                    <div className="form-group row" style={{ marginTop: 15 }}>
                                        <label class="col-sm-4 col-form-label">Responsible: </label>
                                        <div class="col-sm-6">
                                            <select
                                                type="text"
                                                className="form-select"
                                                name='responsible'
                                                value={actionform.responsible}
                                                onChange={onChangeActie}>
                                                <option selected>Select...</option>

                                                {
                                                    adressen.map((email) => {

                                                        return (<option value={email}>{email}</option>)
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    <div className="form-group row" style={{ marginTop: 15 }}>
                                        <label class="col-sm-4 col-form-label">Duration (minutes):</label>
                                        <div class="col-sm-6" >
                                            <Input

                                                type="number"
                                                className="form-control"
                                                name="duur"
                                                min="0"
                                                value={actionform.duur}
                                                onChange={onChangeActie}
                                                validations={[required]}
                                            />
                                        </div>

                                    </div>

                                    <div>
                                        <div className='d-flex justify-content-end'>
                                            <Button variant="primary m-2" data-form="actionshow" onClick={addAction} >
                                                {actionform.new?'Add':'Edit'}
                                            </Button>
                                            <Button variant="secondary m-2" data-form='actionshow' onClick={() => setShowActionForm(false)} >
                                                Close
                                            </Button>
                                        </div>
                                    </div>
                                    <CheckButton
                                        style={{ display: "none" }}
                                        ref={c => {
                                            actioncheckBtn = c;
                                        }}
                                    />
                                </Form>

                            </div>
                        } />
                    <ModalForm show={showinfoform}
                        header='Add informed'
                        children={
                            <Form noValidate
                                value={info}


                                ref={c => {
                                    infoform = c;
                                }}
                            ><div className="form-group row" style={{ marginTop: 15 }}>
                                    <label class="col-sm-4 col-form-label">Name: </label>
                                    <div class="col-sm-6" >
                                        <Input
                                            autoFocus
                                            type="text"
                                            className="form-control"
                                            name="name"

                                            value={info.name}
                                            onChange={onChangeInform}
                                            validations={[required]}
                                        />
                                    </div>

                                </div>
                                <div className="form-group row" style={{ marginTop: 15 }}>
                                    <label class="col-sm-4 col-form-label">Email: </label>
                                    <div class="col-sm-6">
                                        <Input noValidate

                                            type="text"
                                            className="form-control"
                                            name="email"
                                            placeholder="fill in email address..."
                                            value={info.email}
                                            onChange={onChangeInform}

                                            validations={[required]}
                                        />
                                    </div>
                                </div>

                                <div className=''>
                                    <div className='d-flex justify-content-end'>
                                        <Button variant="primary m-2" data-form="show" onClick={addInformed}>
                                            Add
                                        </Button>
                                        <Button variant="secondary m-2" data-form="show" onClick={() => setShowInfoForm(false)}>
                                            Close
                                        </Button>
                                    </div>
                                </div>
                                <CheckButton
                                    style={{ display: "none" }}
                                    ref={c => {
                                        infocheckBtn = c;
                                    }}
                                />
                            </Form>
                        } />
                    <div class="col">
                        <Form noValidate

                            onSubmit={handleRegister}
                            ref={c => {
                                form = c;
                            }}
                        >

                            <div class="col m-3 rounded shadow" style={{ "background-color": "whitesmoke" }}>
                                <div style={{ padding: 15 }}>
                                    <h4>{finishedActions + " / " + totalActions}</h4>


                                    <div className="form-group row" style={{ marginTop: 15 }}>
                                        <label class="col-sm-4 col-form-label">Task name: </label>
                                        <div class="col-sm-6" >
                                            <Input noValidate
                                                type="text"
                                                disabled={!newtask}
                                                className="form-control"
                                                name="taak_name"
                                                placeholder="fill in task name..."
                                                value={taak.taak_name}
                                                onChange={onChangeTask}
                                                validations={[required]}
                                            />
                                        </div>

                                    </div>
                                    <div className="form-group row" style={{ marginTop: 15 }}>
                                        <label class="col-sm-4 col-form-label">Responsible: </label>
                                        <div class="col-sm-6" >
                                            <select
                                                name='taak_responsible'
                                                type="text"
                                                disabled={!newtask}
                                                className="form-select"
                                                value={taak.taak_responsible}
                                                onChange={onChangeTask}>
                                                <option value="" disabled selected>Select...</option>
                                                {
                                                    adressen.map((taken) => {

                                                        return (<option value={taken}>{taken}</option>)
                                                    })
                                                }
                                            </select>
                                        </div>

                                    </div>
                                    <div className="form-group row" style={{ marginTop: 15 }}>
                                        <label class="col-sm-4 col-form-label">Due date: </label>
                                        <div class="col-sm-6" >
                                            <Input
                                                type="date"
                                                disabled={!newtask}
                                                className="form-control"
                                                name="taak_oplevering_plan"
                                                value={moment(taak.taak_oplevering_plan).format('YYYY-MM-DD')}

                                                onChange={onChangeTask}
                                                validations={[required]}
                                            />
                                        </div>

                                    </div>
                                    <div className="form-group row" style={{ marginTop: 15 }}>
                                        <label class="col-sm-4 col-form-label">Duration (days): </label>
                                        <div class="col-sm-6" >
                                            <Input
                                                type="number"
                                                disabled={!newtask}
                                                className="form-control"
                                                name="taak_duur"
                                                value={taak.taak_duur}
                                                min={1}
                                                placeholder={"Auto"}
                                                onChange={onChangeTask}

                                            />
                                        </div>

                                    </div>

                                </div>
                            </div>


                            {actiongroups.map((actions, index) => {
                                return (
                                    <div class="col m-3  p-3 rounded shadow" style={{ "background-color": "whitesmoke" }}>
                                        <div class="d-flex justify-content-between">
                                            <h4>Deliverables group #{index+1}</h4>
                                            <>
                                                {newtask && <Button
                                                    data-rij={index}
                                                    onClick={() => {setActionForm({new:true}); setShowActionForm(true); setCurrentRow(index) }}
                                                    variant={'success'}
                                                    title='Add action'
                                                >+</Button>}
                                            </>
                                        </div>

                                        <div style={{ overflow: 'auto', whiteSpace: 'nowrap' }}>
                                            {actions.map(({ responsible, omschrijving, status, actie_start,veld,soort }, idx) => {
                                                return (
                                                    <div style={{ display: 'inline-block' }}>
                                                        <ActionTile select={setCurrent} selected={index === currentrow && idx === currenttile} soort={veld?Object.keys(JSON.parse(veld))[0]:soort} kolom={index} rij={idx} email={responsible} plan={actie_start} status={status ?? 0} omschrijving={omschrijving} newaction={newtask}></ActionTile>
                                                    </div>
                                                )
                                            })
                                            }
                                        </div>
                                    </div>
                                )
                            })
                            }

                            <div class="col m-3 p-3 rounded shadow" style={{ "background-color": "whitesmoke" }}>
                                <div class="d-flex justify-content-between">
                                    <h4>Informed</h4>
                                    <>
                                        {newtask && <Button
                                            onClick={() => setShowInfoForm(true)}
                                            variant={'success'}
                                            title='Add person to be informed'
                                        >+</Button>}
                                    </>
                                </div>

                                <div class="col-sm-6" >
                                    {taak.taak_informed.length > 0 && <div class="list-group">
                                        {taak.taak_informed.map(({ nickname, infoemail, status }, index) => {
                                            return (
                                                <button value={index} title={"double click to delete"} type="button" class="list-group-item list-group-item-action">
                                                    <div className="ms-2 me-auto">
                                                        <div className="fw-bold">{nickname}</div>
                                                        {infoemail}
                                                        {!status && <div className="warningtext">This person is not yet registered at JEKT.nl. We have prepared an email template for you <a className="warningtext" href={"mailto:" +infoemail+"?subject=I%20want%20to%20inform%20you%20of%20a%20task%20at%20JEKT.nl&body=Hi "+nickname+",%0D%0AI%20would%20like%20to%20keep%20you%20posted%20on%20a%20task%20in%20JEKT.nl,%20but%20it%20seems%20that%20your%20email%20address%20is%20not%20yet%20registered%20there.%0D%0APlease go to https://www.jekt.nl/signup to sign up for a free account or let me know with what address you are registered there.%0D%0A%0D%0AKind regards, %0D%0A" +username}>here</a> to invite</div>}
                                                    </div>
                                                </button>
                                            )
                                        })}
                                    </div>}
                                </div>
                            </div>
                            {newtask && <div className=''>
                                <div className='d-flex justify-content-end'>
                                    <Button variant="success m-1" type='submit'  >
                                        Schedule task
                                    </Button>
                                    <Button variant="outline m-1" onClick={()=>window.history.back()} >
                                        Close
                                    </Button>
                                </div>
                                <div className='m-3'></div>
                            </div>
                            }
                            <CheckButton
                                style={{ display: "none" }}
                                ref={c => {
                                    checkBtn = c;
                                }}
                            />
                        </Form>
                    </div>
                    {taak.taak_log.map((log) => {
                        return <p>{log}</p>
                    })}
                </div>
                <div>

                </div>
            </div>
        </div >




    )



}



