import React, { Component } from "react";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css"

import AuthService from "./services/auth.service";
import CreateProject from "./components/create-project.component";
import Task from "./components/task.component";
import ProjectsList from "./components/projects.component";
import TaskList from "./components/tasklist.component";
import Action from "./components/action.component";
import Navbar from './components/Navbar'
import ProjectView from "./components/project.component";
import AddMember from "./components/add-member.component";
import Register from "./components/register.component";
import Login from "./components/login.component";
import OverView from "./components/overview.component";

import Actions from "./components/actions.component";



class App extends Component {
  constructor(props) {
    super(props);
    this.logOut = this.logOut.bind(this);

    this.state = {

      username: undefined,
    };
  }



  logOut() {
    AuthService.logout();
    this.setState({
      showModeratorBoard: false,
      showAdminBoard: false,
      currentUser: undefined,
    });
  }
  render() {
    return (
      
     
      <div> 
        <div> <Navbar  />
            
              <Router>



                


              <Route path="/" exact component={OverView} />
                  <Route path="/projects" exact component={ProjectsList} />
                  <Route path="/tasks" exact component={TaskList} />
                  <Route path="/project/:id" exact component={ProjectView} />
                  <Route path="/create" exact component={CreateProject} />
                  <Route path="/addmember/:id" exact component={AddMember} />
                  <Route path="/task/:tasknumber" exact component={Task} />
                  <Route path="/deliverables" exact component={Actions} />
                  <Route path="/signup" exact component={Register} />
                  <Route path="/login" exact component={Login} />
                  <Route path="/deliverable/:id" exact component={Action} />
               
              </Router>

            </div>
</div>
         
      
      
    );
  }
}

export default App;