import React, { Component } from 'react';

import MobileBar from './MobileBar';




export default function Menu(props) {


   

        return (
            <div className='wrapmenu'>
                <MobileBar />

                <div className="menu">


                    <hr />
                    <li className='menulink header'><a href='/profile'>{props.user}</a></li>
                    <li className='menulink'><a href='/'>Overview</a></li>

                    <ul>
                    </ul>


                    <hr />
                    <li className='menulink header'><a href='/projects'>Organisation</a></li>
                    <li className='menulink'><a href='/create/'>Manage organisation</a></li>
                    <ul>




                    </ul>


                    <hr />
                    <li className='menulink header'><a href='/projects'>Projects</a></li>
                    <li className='menulink'><a href='/create/'>Create Project</a></li>
                    <ul>




                    </ul>

                    <hr />
                    <li className='menulink header'><a href='/tasks'>Tasks</a></li>
                    <li className='menulink' ><a href='/task/new' >Schedule Task</a></li>
                    <ul>

                    </ul>



                    <hr />
                    <li className='menulink header'><a href='/deliverables'>Deliverables</a></li>
                    <li className='menulink' ><a href='/deliverable/new' >Plan deliverable</a></li>
                    <ul>

                    </ul>


                </div>

            </div>


        )
    }

