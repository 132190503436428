
import Modal from 'react-bootstrap/Modal'





function ModalForm(props) {
  const Input = ({...props }) => (
    <div>
      <input {...props} />
     
    </div>
  );

  return (
    <>
    

      <Modal show={props.show} onHide={props.closeModal}>
        <Modal.Header>
          <Modal.Title>{props.header}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
      
       {props.children}

        </Modal.Body>
     
      </Modal>
    </>
  );
}

export default ModalForm