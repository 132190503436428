import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import React, { Component } from 'react';
import logo from "../components/logo192.png";
import Button from 'react-bootstrap/Button'
import "../index.css"
import authService from "../services/auth.service";
import DropdownButton from "react-bootstrap/esm/DropdownButton";
import Dropdown from "react-bootstrap/esm/Dropdown";


export default class MobileBar extends Component {



  onLogOut(){
    authService.logout();
    window.open('/', '_self')
  }
 



    render() {
      return (
       
        <nav class="menu d-block d-md-none">
       
          <div class="container-fluid">

<DropdownButton

    key={'success'}
    id={`dropdown-variants-success`}
    variant={'success'}
    title='Menu'
>

    <Dropdown.Item eventKey="2" value="Frans" onClick={this.openModal}>Informed</Dropdown.Item>

    <Dropdown.Divider />
    <Dropdown.Item eventKey="1" onClick={this.addActionGroup}>Action group</Dropdown.Item>
</DropdownButton>


</div>
          
       
      </nav>
        

       ) }
}


