
import React, { useState } from 'react';
import Button from 'react-bootstrap/esm/Button';



export default function Signature(props) {
   var value
   
   var zin = props.value===''
   var done = props.disabled??false
   console.log(zin + " + " + done)

if(zin && props.disabled){ value = "Signed by: " +props.responsible +" on: " + new Date();props.onAdd(
    {    target:{value: value}})}
if(!zin && !props.disabled){ value = "";props.onAdd(
        {    target:{value: value}})}
  

    

    
        return (
            <>
               {<label>{props.value}</label>}
              
               

            </>
        )




    

}
