
import React, { useState } from 'react';

import moment from 'moment';
import Button from 'react-bootstrap/esm/Button';
import "../index.css"



export default function ActionTile(props) {

    
    const imagelist = [{ image: '/0.png', tekst: 'Waiting' }, { image: '/1.png', tekst: 'Planned' }, { image: '/2.png', tekst: 'Completed' }]

 
 var randje = props.selected?'1px solid red':'none'
    return (
        
        <div className='tile '  style={{ border: randje}}>
            <div className='card shadow-sm m-1 ' style={{ width: 200}}>



                <div className='card-header' >
                    <div className="fw-bold m-1" >{props.omschrijving}</div>
                    <div className='m-1' style={{ fontSize: 11 }} >{props.email}</div>
                </div>
                <div className="card-body p-1" >

                    <div className='col m-1'>
                    <div className='d-flex justify-content-between'> 
                    <div className='m-1'>Status:  </div>
                   

                        <div><img src={imagelist[props.status].image} alt={imagelist[props.status].tekst} height="20" ></img>
                        <label className='label m-1' style={{ marginLeft: 5, marginRight: 20 }}>  {imagelist[props.status].tekst}</label></div>
</div>
                    </div>
                    {props.plan && <div className='col m-1'>
                        <label >Due date: </label>
                        <span>{moment(props.plan).format("DD.MM.yyyy")}</span>
                    </div>

                    }
                    
                    <div className='col m-1'>
                    <div className='d-flex justify-content-between'> 
                        <div>Type: 
                        {" " +props.soort}</div>
                        </div>
                        
                    </div>
                     { <Button
                                                    data-rij={props.kolom}
                                                    data-tile={props.rij}
                                                    onClick={props.select}
                                                    variant={'success'}
                                                    
                                                >{props.newaction?'Edit':'Open'}</Button>}
                </div>




            </div>

        </div>


    )






}
