import React, { Component } from 'react';

import axios from 'axios';
import moment from 'moment';
import authService from '../services/auth.service';
import Menu from "./menu.component";
import NavDropdown from 'react-bootstrap/NavDropdown'
import Button from 'react-bootstrap/Button'
import Nav from 'react-bootstrap/Nav'


import "../index.css"


var actie;


export default class Actions extends Component {


    constructor(props) {
        super(props);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.filterType = this.filterType.bind(this);
        this.filterStatus = this.filterStatus.bind(this);
        this.sortList = this.sortList.bind(this);
        this.onChangeFilter = this.onChangeFilter.bind(this);
        this.usedFilters = { status: "", type: "" };

        this.state = { responsible_actions: [], filter_actions: [], username: "Login", soortFilter: "Type", statusFilter: "Status" };

    }


    componentDidMount() {
        const user = authService.getCurrentUser();
        if (user === 1) { return this.reDirect() }

        var userObject = { user: user }

        axios.post(authService.apiService() + "deliverables", userObject)
            .then(response => {


                var responseLijst = response.data.deliverables;






                responseLijst.forEach(element => {
                    if (element['status'] === undefined) {
                        element['status'] = 0
                    };
                });

                this.setState({
                    username: response.data.username,

                    responsible_actions: responseLijst,
                    filter_actions: responseLijst,

                });



            })
            .catch((error) => {

                if (error.status === 401) {
                    this.reDirect();

                }


            })
    }

    reDirect() {
        this.props.history.push('/login');
    }

    handleInputChange(event) {


        var index = event.target.id;

        this.props.history.push("/deliverable/" + index)
    }

    filterAll() {
        var name = [...this.state.responsible_actions];
        let filteredType = name
        if (this.usedFilters.type !== "") {
            filteredType = name.filter(taak => {
                if (taak.veld && Object.keys(JSON.parse(taak.veld))[0].includes(this.usedFilters.type)) {
                    return taak
                }
                return false
            });
        }
        let filteredStatus = filteredType

        if (this.usedFilters.status !== "") {
            filteredStatus = filteredType.filter(vaak => {
                if (parseInt(vaak.status)===parseInt(this.usedFilters.status)) {
                    return vaak
                }
                return false
            });
        }


        return filteredStatus

    }

    filterType(eventKey) {
        this.usedFilters['type'] = eventKey;

       var returnKey = eventKey===""?eventKey = "Type": eventKey
       

        this.setState({
            soortFilter: returnKey,
            filter_actions: this.filterAll()
        })


    }

    filterStatus(eventKey) {
       
        this.usedFilters['status'] = eventKey;
        var returnKey = eventKey===""?eventKey = "Status": eventKey 

        this.setState({
            statusFilter: eventKey,
            filter_actions: this.filterAll()
        })


    }
    onChangeFilter(e) {
        let name = [...this.state.responsible_actions];
        let filter = e.target.value;
        let filteredName = name.filter(taak => {
            if (taak.omschrijving.toLowerCase().includes(filter.toLowerCase()) ) {

                return taak
            }
            return false
        });


        this.setState({
            filter_actions: filteredName
        })

    }
    sortList(e) {
        let sortedName = [...this.state.responsible_actions];
        let sortedField = e.target.value;
        sortedName.sort((a, b) => {
            if (a[sortedField] < b[sortedField]) {
                return -1;
            }
            if (a[sortedField] > b[sortedField]) {
                return 1;
            }
            return 0;
        });

        this.setState({
            filter_actions: sortedName
        })

    }

    render() {

        let status_omschrijving = ["Waiting", "Start", "Completed"]
        return (

            <div className="indexcontainer">
                <div><Menu user={this.state.username} /></div>
                <div class='container' style={{ 'padding-top': '1em' }} >


                    <div class="d-flex justify-content-between">
                        <h2>Deliverables</h2>
                        <button type="button" class="btn btn-success shadow-sm" onClick={() => { this.props.history.push("/deliverable/new") }}>Plan Deliverable</button>

                    </div>
                    <div class="container" style={{ marginTop: 30 }} >
                        <div class="col rounded shadow" style={{ "background-color": "whitesmoke", padding: 15 }}>

                            <div class="d-flex justify-content-between">


                                <div>
                                    <input type="text"

                                        className="form-control"
                                        placeholder="Search..."
                                        onChange={this.onChangeFilter}
                                    />
                                </div>

                            </div>





                            <div class='table-responsive-sm'>
                                <table className='table table-hover  table-borderless' style={{ marginTop: 20 }} >

                                    <thead>
                                       
                                        <tr>
                                            <th> <NavDropdown title={this.state.statusFilter>=0?<img src={"/" +this.state.statusFilter+".png"} alt="0" height='25' />:<strong>Status</strong>} onSelect={this.filterStatus}>
                                                <NavDropdown.Item eventKey="" >All</NavDropdown.Item>
                                                <NavDropdown.Item eventKey="0" ><img src="/0.png" alt="0" height='25' /></NavDropdown.Item>
                                                <NavDropdown.Item eventKey="1"><img src="/1.png" alt="0" height='25' /></NavDropdown.Item>
                                                <NavDropdown.Item eventKey="2"><img src="/2.png" alt="0" height='25' /></NavDropdown.Item>

                                            </NavDropdown> </th>
                                            <th> <NavDropdown title={<strong>{this.state.soortFilter}</strong>} onSelect={this.filterType}>
                                                <NavDropdown.Item eventKey="" >All</NavDropdown.Item>
                                                <NavDropdown.Item eventKey="action" >Action</NavDropdown.Item>
                                                <NavDropdown.Item eventKey="tekst">Text</NavDropdown.Item>
                                            </NavDropdown> </th>
                                            <th><Nav.Link><strong>Deliverable</strong></Nav.Link></th>
                                            <th><NavDropdown title={<strong>Plan</strong>}>
                                            <NavDropdown.Item eventKey="" ><input type="date"></input></NavDropdown.Item>
                                            </NavDropdown>
                                            
                                            </th>

                                            <th><Nav.Link><strong>Responsible</strong></Nav.Link></th>
                                            
                                        </tr>

                                    </thead>


                                    <tbody>



                                        {this.state.filter_actions.map(({ _id, status, omschrijving, actie_start, veld, responsible }, index) => {
                                            var stat = status === undefined ? '0' : status;
                                            let statustekst = "/" + stat + ".png"
                                            return (
                                                <tr onClick={this.handleInputChange} id={_id} name={omschrijving}>
                                                    <td><img src={statustekst} title={status_omschrijving[status]} alt="0" height='25' id={_id} /></td>
                                                    <td id={_id}>{veld && Object.keys(JSON.parse(veld))[0]}</td>
                                                    <td id={_id} title="omschrijving" >{omschrijving}</td>
                                                    <td id={_id}> {moment(actie_start).format("DD/MM/YYYY")}</td>

                                                    <td id={_id}> {responsible}</td>
                                                    <td id={_id} class="justify-content-sm-start">



                                                    </td>
                                                </tr>
                                            )

                                        })

                                        }


                                    </tbody>

                                </table>
                            </div>
                        </div>
                    </div>
                    <div></div>
                </div>
            </div>




        )
    }
}

