
import React, { useState } from 'react';
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/esm/Button';



export default function Tekst(props) {

    
  
const waarde = props.value
    

    
        return (
            <>
                <Form.Control as="textarea" rows={5} value={waarde} onChange={props.onAdd}  disabled={props.disabled} />
             
               

            </>
        )




    

}
