import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import Navbar from './Navbar';
import axios from 'axios';
import moment from 'moment';
import authService from '../services/auth.service';
import Menu from "./menu.component";

import "../index.css"
import ProgressBar from 'react-bootstrap/esm/ProgressBar';

var actie;


export default class ProjectsList extends Component {


    constructor(props) {
        super(props);
        this.handleInputChange = this.handleInputChange.bind(this);

        this.state = { projects: [], checkedList: [], message: "", username: "Login", menu: { taken: [], menuprojects: [] } };

    }


    componentDidMount() {
        const user = authService.getCurrentUser();
        if (user === 1) { return this.reDirect() }

        var userObject = { user: user }

        axios.post(authService.apiService(), userObject)
            .then(response => {


                var responseLijst = response.data.projects;
                var menutaken = response.data.menutaken.menutaken;
                var menuprojecten = response.data.menutaken.menuprojecten;


                responseLijst.forEach(element => {
                    element['checked'] = false;
                });

                this.setState({
                    username: response.data.username,

                    projects: responseLijst,
                    menu: { taken: menutaken, menuprojects: menuprojecten }

                });



            })
            .catch((error) => {

                if (error.status === 401) {
                    this.reDirect();

                }


                console.log(this.state.menu);
            })
    }

    reDirect() {
        this.props.history.push('/login');
    }

    handleInputChange(event) {
        const stateLijst = this.state;

        var index = event.target.id;

        const checked = stateLijst.projects[index].checked;
        if (checked) { stateLijst.projects[index].checked = false } else { stateLijst.projects[index].checked = true };

        const verzendLijst = [];

        stateLijst.projects.map((element) => {
            if (element.checked) { verzendLijst.push(element._id) }


        });
        stateLijst.checkedList = verzendLijst;
        actie = verzendLijst[0];

        this.setState(stateLijst);
    }



    render() {
        let linkLijst = [{ linkname: "Open", link: "/project/" + actie, klasse: 1 }, { linkname: "Delete", link: "/", klasse: 1 }];
        return (
            
                <div className="indexcontainer">
                    <div><Menu user={this.state.username}  /></div>
                    <div  >


                        <div style={{ margin: '1em' }}>
                            <h1>Projects</h1>
                            <div class="  d-md-flex justify-content-sm-start">






                                {
                                    linkLijst.map((link) => {
                                        const aantalSelected = this.state.checkedList.length;

                                        if (link.klasse <= aantalSelected) {
                                            return (
                                                <Link to={link.link} className="nav-link"> {link.linkname}</Link>


                                            )
                                        }

                                    })}



                            </div>
                            <table className='table' size='sm' style={{ marginTop: 20 }} >
                                <thead>
                                    <tr> <th><input type="checkbox" checked={false}></input></th>
                                        <th>Project</th>
                                        <th>Start date</th>
                                        <th>End date</th>
                                        <th>Progress</th>



                                    </tr>
                                </thead>
                                <tbody>



                                    {this.state.projects.map(({ project_name, taken_aantal_totaal, taken_aantal_klaar, taken_aantal_wachten, taken_aantal_start, project_start, project_end, checked, _id }, index) => {
                                        const klaar = taken_aantal_klaar / taken_aantal_totaal * 100;
                                        const wachten = taken_aantal_wachten / taken_aantal_totaal * 100;
                                        const start = taken_aantal_start / taken_aantal_totaal * 100;
                                        return (
                                            <tr><td>
                                                <input type="checkbox" checked={checked} onChange={this.handleInputChange} id={index} name={project_name} ></input>
                                            </td>
                                                <td ><a tabindex="0" className="nav-link" href={"/project/" + _id} data-toggle="popover" data-trigger="focus" title="en hier komt dus informatie over het project" >{project_name}</a>
                                                </td>
                                                <td> {moment(project_start).format("DD/MM/YYYY")}</td>
                                                <td> {moment(project_end).format("DD/MM/YYYY")}</td>
                                                <td class="justify-content-md-start">
                                                    <ProgressBar>
                                                        <ProgressBar variant="success" min='0' max='100' now={klaar} />
                                                        <ProgressBar variant="warning" min='0' max='100' now={start} />
                                                        <ProgressBar variant="danger" min='0' max='100' now={wachten} />
                                                    </ProgressBar>


                                                </td>
                                            </tr>
                                        )

                                    })

                                    }


                                </tbody>

                            </table>

                        </div>
                        <div></div>
                    </div>
                </div>
            



        )
    }
}

