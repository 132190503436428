import React, { Component } from 'react';
import axios from 'axios';
import authService from '../services/auth.service';
import Navbar from './Navbar';
export default class AddMember extends Component {

    constructor(props) {
        super(props);

        this.onChangeEmail = this.onChangeEmail.bind(this);
        this.onChangeName = this.onChangeName.bind(this);
        this.onChangeRole = this.onChangeRole.bind(this);

        this.onSubmit = this.onSubmit.bind(this);

        this.state = {
            username: 'ikke',
           name: '',
           email: '',
           role: ''

        }
    }

    componentDidMount() {
        const user = authService.getCurrentUser();
        if (user === 1) { return this.reDirect() }

        var userObject = { user: user } 
        axios.post(authService.apiService() + this.props.match.params.id +'/project', userObject
        )
            .then(response => {

                this.setState({
                  username: response.data.username
                
                });
            })
            .catch(function (error) {
                console.log(error);
            })

    }
    reDirect(){
        this.props.history.push('/login');
    }

    onChangeEmail(e) {
        this.setState({
            email: e.target.value
        });
    }

    onChangeName(e) {
        this.setState({
            name: e.target.value
        });
    }
    onChangeRole(e) {
        this.setState({
            role: e.target.value
        });
    }



    onSubmit(e) {
        e.preventDefault();
       
        const user = authService.getCurrentUser();
        if(user===1){return this.reDirect()}
       



        const newMember = {
            user: user,
            team_member_name: this.state.name,
            //userID is het emailadres
            user_ID: this.state.email,
            project_team_role: this.state.role
           

        };

        var projectid = this.props.match.params.id;
        var postlink = authService.apiService() + projectid + '/addmember'
        axios.post(postlink, newMember)
            .then();
        window.location.replace('/project/' + projectid, "_self");
    }
    

    render() {
        return (
            
            
            <div class="indexcontainer" >
                <div></div>
                <div>
            <div class="container" style={{ marginTop: 10 }}>
                <h3>Add team member</h3>
                <div >
                <form onSubmit={this.onSubmit} className="mb-4">

                    <div className="form-group row" style={{ marginTop: 5 }}>
                        <label class="col-sm-4 col-form-label">Name: </label>
                        <div class="col-sm-4">
                            <input type="text"

                                className="form-control"
                                value={this.state.name}
                                onChange={this.onChangeName}
                            />
                        </div>

                    </div>
                   

                    <div className="form-group row" style={{ marginTop: 10 }}>
                        <label class="col-sm-4 col-form-label">E-mail </label>
                        <div class="col-sm-4">
                            <input
                                type="text"
                                className="form-control"
                                value={this.state.email}
                                onChange={this.onChangeEmail}
                            />
                        </div> 
                    </div> 

                    <div className="form-group row" style={{ marginTop: 10 }}>
                        <label class="col-sm-4 col-form-label">Role </label>
                        <div class="col-sm-4">
                            <input
                                type="text"
                                className="form-control"
                                value={this.state.role}
                                onChange={this.onChangeRole}
                            />
                        </div> 
                    </div> 
                   

                    <div className="form-group">
                        <input type="submit" value="Add member" className="btn btn-primary" style={{ marginTop: 5 }}/>
                    </div>
                </form>
                </div>
            </div>
            </div>
            </div>
            
        )
    }
}