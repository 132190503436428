import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import Navbar from './Navbar';
import axios from 'axios';
import moment from 'moment';
import authService from '../services/auth.service';
import Menu from "./menu.component";
import Button from 'react-bootstrap/Button'


import "../index.css"
import ProgressBar from 'react-bootstrap/esm/ProgressBar';

var actie;


export default class TaskList extends Component {


    constructor(props) {
        super(props);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.sortList = this.sortList.bind(this);
        this.onChangeFilter = this.onChangeFilter.bind(this);


        this.state = { responsible_taken: [], filter_taken: [], username: "Login" };

    }


    componentDidMount() {
        const user = authService.getCurrentUser();
        if (user === 1) { return this.reDirect() }

        var userObject = { user: user }

        axios.post(authService.apiService() + "tasks", userObject)
            .then(response => {


                var responseLijst = response.data.taken;


            



                responseLijst.forEach(element => {
                    element['checked'] = false;
                });

                this.setState({
                    username: response.data.username,

                    responsible_taken: responseLijst,
                    filter_taken: responseLijst,

                });



            })
            .catch((error) => {

                if (error.status === 401) {
                    this.reDirect();

                }


            })
    }

    reDirect() {
        this.props.history.push('/login');
    }

    handleInputChange(event) {


        var index = event.target.id;

        this.props.history.push("/task/" + index)


    }
    onChangeFilter(e) {
        let name = [...this.state.responsible_taken];
        let filter = e.target.value;
        let filteredName = name.filter(taak => {
            if (taak.taak_name.toLowerCase().includes(filter.toLowerCase()) || taak.taak_status === parseInt(filter)) {

                return taak
            }
            return false
        });


        this.setState({
            filter_taken: filteredName
        })

    }
    sortList(e) {
        let sortedName = [...this.state.responsible_taken];
        let sortedField = e.target.value;
        sortedName.sort((a, b) => {
            if (a[sortedField] < b[sortedField]) {
                return -1;
            }
            if (a[sortedField] > b[sortedField]) {
                return 1;
            }
            return 0;
        });

        this.setState({
            filter_taken: sortedName
        })

    }

    render() {

        let status_omschrijving = ["Waiting", "Start", "Completed"]
        return (
            
                <div className="indexcontainer">
                    <div><Menu user={this.state.username} /></div>
                    <div class='container' style={{ 'padding-top': '1em' }} >


                        <div class="d-flex justify-content-between">
                            <h2>Tasks</h2>
                            <button type="button" class="btn btn-success shadow-sm" onClick={() => { this.props.history.push("/newtask") }}>Schedule task</button>

                        </div>
                        <div class="container" style={{ marginTop: 30 }} >
                            <div class="col rounded shadow" style={{ "background-color": "whitesmoke", padding: 15 }}>

                                <div class="d-flex justify-content-between">
                                    <button type="button" value={'taak_name'} class='btn' onClick={this.sortList}>Task</button>

                                    <div>
                                        <input type="text"

                                            className="form-control"
                                            placeholder="Search..."
                                            onChange={this.onChangeFilter}
                                        />
                                    </div>

                                </div>





                                <div class = 'table-responsive-sm'>
                                    <table className='table table-hover  table-borderless' style={{ marginTop: 20 }} >

                                        <thead>

                                            <tr>
                                                <th>Status</th>
                                                <th>Task</th>
                                                <th>Start date</th>
                                                <th>End date</th>
                                                <th>Initiator</th>

                                            </tr>

                                        </thead>


                                        <tbody>



                                            {this.state.filter_taken.map(({ _id, taak_status, taak_name, taak_start, taak_end, taak_initiator, checked }, index) => {
                                                let status = "/" + taak_status + ".png"
                                                return (
                                                    <tr onClick={this.handleInputChange} id={_id} name={taak_name}>
                                                        <td><img src={status} title={status_omschrijving[taak_status]} alt="0" height='25' id={_id} /></td>
                                                        <td id={_id} title="taak omschrijving" >{taak_name}</td>
                                                        <td id={_id}> {moment(taak_start).format("DD/MM/YYYY")}</td>
                                                        <td id={_id}> {moment(taak_end).format("DD/MM/YYYY")}</td>
                                                        <td id={_id}> {_id}</td>
                                                        <td id={_id} class="justify-content-sm-start">



                                                        </td>
                                                    </tr>
                                                )

                                            })

                                            }


                                        </tbody>
                                        
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div></div>
                    </div>
                </div>
            



        )
    }
}

